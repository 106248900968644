



















import { Services, ServiceType } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

interface PasswordResetToken {
  u: string;
  e: string;
}

@Component
export default class ResetPassword extends Vue {
  private noTokens = false;
  private loading = true;
  private success = false;

  private token = '';
  private decodedToken: PasswordResetToken | null = null;
  private newPassword = '';
  private email = '';

  mounted () {
    this.loading = false

    const token = this.$route.query.token as string

    if (!token) {
      this.noTokens = true
      return
    }

    this.token = token
    this.decodedToken = JSON.parse(atob(token.split('.')[0])) as PasswordResetToken

    this.email = this.decodedToken.e
  }

  async submit () {
    this.loading = true

    const result = await Services.API.Auth.ResetPasswordSubmit({
      Email: this.email,
      Token: this.token,
      NewPassword: this.newPassword
    })

    this.$router.push('/account/login?reset=true')
  }
}
